import React from 'react';

import Password from 'src/components/famille/password';
import { withGuard } from 'src/components/admin/guard';
import FamilleLayout from 'src/components/layouts/famille';

import SEO from 'src/components/seo';

const EditPasswordPage = ({ userProfile }) => {
  return (
    <FamilleLayout userProfile={userProfile}>
      <SEO title="Espace adhérent - changer le mot de passe" />
      <Password />
    </FamilleLayout>
  );
};

export default withGuard(EditPasswordPage);
