import React from 'react';
import * as DesignSystem from 'src/components/design-system';
import * as Form from 'src/components/design-system/form';
import ErrorAlert from 'src/components/design-system/alerts/error';
import Submit from 'src/components/design-system/buttons/submit';
import Cancel from 'src/components/design-system/buttons/cancel';

import useForm, { updateTextField } from 'src/hooks/useForm';
import { isLoading } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';

const EditPassword = ({}) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue: {},
    axiosConfig: {
      method: 'put',
      url: routes.password(),
    },
    formToPayload: (i) => i,
    redirectUrl: '/famille',
  });

  const updateField = updateTextField(setFormData, formData);
  const errors =
    requestState.status === 'error' ? requestState.error.errors : {};

  return (
    <form onSubmit={submitForm}>
      {requestState.status === 'error' && (
        <ErrorAlert
          className="mt-4"
          message="Veuillez corriger l'erreur ci-dessous."
        />
      )}
      <DesignSystem.Card className="mt-4" title="Changement de mot de passe">
        <Form.Line label="Précédent mot de passe" for="old_password">
          <Form.Input
            id="old_password"
            type="password"
            value={formData.old_password}
            onChange={updateField('old_password')}
            errors={errors}
          />
        </Form.Line>
        <Form.Line label="Nouveau mot de passe" for="new_password">
          <Form.Input
            id="new_password"
            type="password"
            value={formData.new_password}
            onChange={updateField('new_password')}
            errors={errors}
          />
        </Form.Line>
        <Form.Line
          label="Confirmation du nouveau mot de passe"
          for="new_password_confirmation"
        >
          <Form.Input
            id="new_password_confirmation"
            type="password"
            value={formData.new_password_confirmation}
            onChange={updateField('new_password_confirmation')}
            errors={errors}
          />
        </Form.Line>
        <Form.Footer>
          <Cancel to={`/famille/`}>Retour à l'accueil</Cancel>
          <Submit
            type="submit"
            disabled={isFormPristine}
            isLoading={isLoading(requestState)}
          >
            Mettre à jour
          </Submit>
        </Form.Footer>
      </DesignSystem.Card>
    </form>
  );
};

export default EditPassword;
